import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import boxShadowGenerator from '@utils/boxShadowGenerator';
import { mediaQuery, Spacings } from '@styles/index';
import Image from '@base/FutureImage';
import { addBannerParamToURL } from '@cont/NewPreSubscribed/utils';

const ImageWrapper = styled.div`
  &.web {
    ${mediaQuery.uptoMobile} {
      display: none;
    }
  }

  &.mWeb {
    display: none;
    ${mediaQuery.uptoMobile} {
      display: block;
    }
  }
`;
const BannerImage = styled(Image)`
  border-radius: ${Spacings.SPACING_2B};
  box-shadow: ${boxShadowGenerator()};
`;

const BannerContainer = styled.a`
  display: block;
  position: relative;
`;

const GenericBanner = ({
  block,
  itemWidth,
  itemHeight,
  className,
  priority
}) => {
  const data = block?.data[0];
  const { img, appImg } = data;
  return (
    <BannerContainer
      href={addBannerParamToURL(data.url)}
      target={data.url.includes('http') ? '_blank' : '_self'}
      rel="noreferrer"
      className={className}
    >
      <ImageWrapper className="web">
        <BannerImage
          src={img}
          alt="banner"
          width={itemWidth || 848}
          height={itemHeight || 265}
          priority={priority}
        />
      </ImageWrapper>
      <ImageWrapper className="mWeb">
        <BannerImage
          src={appImg || img}
          alt="banner"
          width={itemWidth || 848}
          height={itemHeight || 265}
          priority={priority}
        />
      </ImageWrapper>
    </BannerContainer>
  );
};

export default GenericBanner;

GenericBanner.propTypes = {
  block: PropTypes.objectOf(PropTypes.any).isRequired,
  itemWidth: PropTypes.number,
  itemHeight: PropTypes.number,
  className: PropTypes.string
};

GenericBanner.defaultProps = {
  itemWidth: 848,
  itemHeight: 265,
  className: undefined
};
